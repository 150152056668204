<template>
  <div>
    <b-modal
      id="detailMerchant"
      size="xl"
      title="Chi tiết Merchant"
      hide-footer
      ref="detailMerchant"
    >
      <table class="table table-striped">
        <tbody>
          <tr>
            <th scope="row" class="col-lg-2">Merchant ID</th>
            <td>{{ this.merchant.id }}</td>
          </tr>
          <tr>
            <th scope="row">Merchant Code</th>
            <td>{{ this.merchant.code }}</td>
          </tr>
          <tr>
            <th scope="row">Tên Merchant</th>
            <td>{{ this.merchant.name }}</td>
          </tr>
          <tr>
            <th scope="row">Loại Merchant</th>
            <td>{{ this.merchant.type }}</td>
          </tr>
          <tr>
            <th scope="row">Mô tả</th>
            <td>{{ this.merchant.description }}</td>
          </tr>
          <tr>
            <th scope="row">Merchant cha</th>
            <td>{{ getNameParent(this.merchant.parent_id, this.items) }}</td>
          </tr>
          <tr>
            <th scope="row">Account ID</th>
            <td>
              {{ this.merchant.accountId }}
              <b-button
                style="float: right"
                @click="showHistoryTransaction(merchant.account_id)"
                variant="outline-primary"
                class="btn-sm"
                >Lịch sử giao dịch
              </b-button>
            </td>
          </tr>
          <tr>
            <th scope="row">IPN</th>
            <td>{{ this.merchant.ipn }}</td>
          </tr>
          <tr>
            <th scope="row">Sản phẩm</th>
            <td>{{ this.merchant.productId }}</td>
          </tr>
        </tbody>
      </table>
    </b-modal>
    <b-modal
      id="updateMerchant"
      size="xl"
      title="Chỉnh sửa Merchant"
      hide-footer
      ref="updateMerchant"
    >
      <b-row>
        <b-col>
          <b-form-group label="Tên Merchant (*)">
            <b-form-input
              v-model="merchant.name"
              placeholder="Nhập tên merchant"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Merchant code (*)">
            <b-form-input
              v-model="merchant.code"
              :disabled="true"
              placeholder="Nhập merchant code"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Merchant cha">
            <b-form-select
              v-model="merchant.parent_id"
              :options="option.merchantParent"
              value-field="id"
              text-field="name"
            >
              <option :value="null">-- Chọn merchant cha --</option>
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Loại Merchant (*)">
            <b-form-select
              v-model="merchant.type"
              :options="option.type"
              :disabled="true"
              required
            ></b-form-select>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Nhập Ipn">
            <b-form-input
              v-model="merchant.ipn"
              placeholder="Nhập Ipn"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="ID sản phẩm">
            <b-form-input
              v-model="merchant.productId"
              placeholder="Nhập sản phẩm cách nhau bởi dấu phẩy. vd: 1,5,3,6,..."
              :disabled="true"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Mã mapping">
            <b-form-input v-model="merchant.type_sdk" placeholder="Mã mapping với cổng thanh toán"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Mô tả">
            <b-form-textarea
              id="textarea"
              v-model="merchant.description"
              placeholder="Mô tả"
              rows="2"
            ></b-form-textarea>
          </b-form-group>
        </b-col>
      </b-row>
      <div class="card-footer" style="text-align: right">
        <button class="btn btn-primary mr-2" @click="updateMerchant()">
          Cập nhật
        </button>
      </div>
    </b-modal>
    <b-card-group deck>
      <b-card header-tag="header" footer-tag="footer">
        <template v-slot:header>
          <h6 class="mb-0">Danh sách tài khoản Merchant</h6>
        </template>
        <b-row>
          <b-col>
            <b-form-group label="Merchant ID">
              <b-form-input
                v-model="merchantSearch.id"
                placeholder=""
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Tên Merchant">
              <b-form-input
                v-model="merchantSearch.name"
                placeholder=""
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Merchant Code">
              <b-form-input
                v-model="merchantSearch.code"
                placeholder=""
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Từ ngày">
              <b-form-input
                :id="`type-date`"
                :type="`date`"
                v-model="merchantSearch.startTime"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Đến ngày">
              <b-form-input
                :id="`type-date`"
                :type="`date`"
                v-model="merchantSearch.endTime"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col></b-col>
        </b-row>
        <b-row>
          <b-col class="text-right">
            <b-button
              class="mr-2"
              variant="outline-primary"
              @click="searchMerchant()"
              >Tìm kiếm
            </b-button>
            <b-button
              class="mr-2"
              variant="outline-primary"
              @click="createForm()"
              >Thêm mới </b-button
            >&nbsp;
          </b-col>
        </b-row>
        <hr />
        <b-table
          :items="items"
          :fields="fields"
          ref="table"
          striped
          hover
          responsive
          caption-top
        >
          <template #cell(parent_name)="data">
            <span v-if="data.item.parent_id !== null">{{
              getNameParent(data.item.parent_id, items)
            }}</span>
          </template>
          <template #cell(options)="data">
            <button @click="showUpdate(data.item)" variant="outline-primary">
              <i class="fa fas fa-edit" style="color: #3699ff"></i></button
            >&nbsp;
            <button @click="showDetail(data.item)">
              <i class="fa fa-search-plus" style="color: #3699ff"></i>
            </button>
          </template>
        </b-table>
        <div class="mt-3">
          <b-pagination-nav
            v-model="currentPage"
            :link-gen="createLinkPage"
            :number-of-pages="paginate.totalPage"
            use-router
            first-number
            last-number
            align="right"
          ></b-pagination-nav>
        </div>
      </b-card>
    </b-card-group>
  </div>
</template>

<script>
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Notifications from "vue-notification";
import Common from "@/core/mixins/common";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Vue from "vue";

const CmsRepository = RepositoryFactory.get("cms");
Vue.use(Notifications);
export default {
  mixins: [Common],
  data() {
    return {
      merchant: {
        id: null,
        name: null,
        code: null,
        description: null,
        parent_id: null,
        product_id: null,
        type: null,
        accountId: null,
        account_id: null,
        productId: "",
        ipn: "",
        type_sdk: null
      },
      merchantSearch: {
        id: null,
        name: null,
        code: null,
        startTime: null,
        endTime: null,
      },
      fields: [
        { id: "Merchant ID" },
        { code: "Code" },
        { type: "Loại Merchant" },
        { name: "Tên Merchant" },
        { parent_name: "Merchant cha" },
        { created_by: "Người tạo" },
        { created_at: "Ngày tạo" },
        { options: "Tùy chọn" },
      ],
      option: {
        merchantParent: [],
        type: [
          { value: "WALLET", text: "Wallet" },
          { value: "GATEWAY", text: "Gateway" },
        ],
      },
      url: this.$route,
      items: [],
      currentPage: 1,
      paginate: {
        limit: 25,
        page: 1,
        totalPage: 1,
        total: 0,
        skip: 0,
      },
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Tài khoản", route: "account" },
      { title: "Tài khoản Merchant", route: "merchant" },
    ]);
  },
  methods: {
    updateMerchant() {
      this.$bus.$emit("show-loading", true);
      let params = {
        id: this.merchant.id,
        name: this.merchant.name,
        type_sdk: this.merchant.type_sdk
      };
      if (this.merchant.productId != null && this.merchant.productId != "") {
        params.product_id = this.merchant.productId;
      }
      if (this.merchant.parent_id != null && this.merchant.parent_id != "") {
        params.parent_id = this.merchant.parent_id;
      }
      if (this.merchant.description != null && this.merchant.productId != "") {
        params.description = this.merchant.description;
      }
      if (this.merchant.ipn != null && this.merchant.ipn != "") {
        params.ipn = this.merchant.ipn;
      }
      CmsRepository.updateMerchant(params)
        .then((response) => {
          if (response.data.error_code) {
            alert(response.data.message);
          } else {
            this.listAll();
            this.$bus.$emit("show-loading", false);
          }
        })
        .catch(() => {
          alert("Có lỗi xảy ra");
        });
      this.$refs["updateMerchant"].hide();
      this.$bus.$emit("show-loading", false);
    },
    listMerchantParent() {
      CmsRepository.listMerchantParent()
        .then((response) => {
          if (response.data.error_code) {
            alert(response.data.message);
          } else {
            this.option.merchantParent = response.data.data;
          }
        })
        .catch(() => {});
    },
    listAll() {
      this.$bus.$emit("show-loading", true);
      let numberPage = 1;
      if (typeof this.$route.query.page != "undefined") {
        numberPage = this.$route.query.page;
      }
      let params = {
        page: numberPage,
      };
      CmsRepository.listMerchantAll(params).then((response) => {
        if (response.data.error_code) {
          Vue.notify({
            group: "foo",
            type: "warn",
            title: response.data.message,
          });
        } else {
          this.items = response.data.data.data;
          this.paginate.total = response.data.data.total;
          this.paginate.totalPage = response.data.data.last_page;
          this.paginate.currentPage = response.data.data.current_page;
        }
      });
      this.$bus.$emit("show-loading", false);
    },
    searchMerchant() {
      let params = {};
      if (
        this.merchantSearch.startTime !== null &&
        this.merchantSearch.startTime !== ""
      ) {
        params.start_time = this.merchantSearch.startTime;
      }
      if (
        this.merchantSearch.endTime !== null &&
        this.merchantSearch.endTime !== ""
      ) {
        params.end_time = this.merchantSearch.endTime;
        if (this.merchantSearch.endTime < this.merchantSearch.startTime) {
          this.notifyAlert("warn", "Ngày bắt đầu phải nhỏ hơn ngày kết thúc");
          return;
        }
      }
      if (this.merchantSearch.id !== null && this.merchantSearch.id !== "") {
        params.id = this.merchantSearch.id;
      }
      if (
        this.merchantSearch.name !== null &&
        this.merchantSearch.name !== ""
      ) {
        params.name = this.merchantSearch.name;
      }
      if (
        this.merchantSearch.code !== null &&
        this.merchantSearch.code !== ""
      ) {
        params.code = this.merchantSearch.code;
      }

      this.$bus.$emit("show-loading", true);
      CmsRepository.listMerchantAll(params)
        .then((response) => {
          if (response.data.error_code) {
            Vue.notify({
              group: "foo",
              type: "warn",
              title: response.data.message,
            });
          } else {
            this.items = response.data.data.data;
            this.paginate.total = response.data.data.total;
            this.paginate.totalPage = response.data.data.last_page;
            this.paginate.currentPage = response.data.data.current_page;
          }
        })
        .catch(() => {
          alert("Có lỗi xảy ra");
        });
      this.$bus.$emit("show-loading", false);
    },
    createForm() {
      this.$router.push("/account/merchant/create");
    },
    showDetail(data) {
      this.merchant.id = data.id;
      this.merchant.name = data.name;
      this.merchant.code = data.code;
      this.merchant.description = data.description;
      this.merchant.parent_id = data.parent_id;
      this.merchant.type = data.type;
      this.merchant.ipn = data.ipn;
      this.merchant.accountId = data.account_id;
      this.merchant.account_id = data.account_id;
      this.merchant.productId = "";
      data.product_merchant.forEach((item) => {
        this.merchant.productId += item.id + ", ";
      });
      this.merchant.productId = this.merchant.productId.replace(/,\s*$/, "");
      if (data.parent_id != null) {
        this.merchant.account_id = this.getAccountParent(
          data.parent_id,
          this.items
        );
      }
      this.$refs["detailMerchant"].show();
    },
    showUpdate(data) {
      this.merchant.id = data.id;
      this.merchant.name = data.name;
      this.merchant.code = data.code;
      this.merchant.description = data.description;
      this.merchant.parent_id = data.parent_id;
      this.merchant.type = data.type;
      this.merchant.ipn = data.ipn;
      this.merchant.accountId = data.account_id;
      this.merchant.account_id = data.account_id;
      this.merchant.productId = "";
      data.product_merchant.forEach((item) => {
        this.merchant.productId += item.id + ",";
      });
      this.merchant.productId = this.merchant.productId.replace(/,\s*$/, "");
      if (data.parent_id != null) {
        this.merchant.account_id = this.getAccountParent(
          data.parent_id,
          this.items
        );
      }
      this.$refs["updateMerchant"].show();
    },
    showHistoryTransaction(accountId) {
      this.$router.push("/account/enterprise/detail/" + accountId);
    },
    getNameParent(id, array) {
      let result = array.filter((item) => {
        if (item.id === id && item.parent_id === null) {
          return item;
        }
      });
      if (result.length) {
        return result[0].name;
      }
      return "";
    },
    getAccountParent(id, array) {
      let result = array.filter((item) => {
        if (item.id === id && item.parent_id === null) {
          return item;
        }
      });
      if (result.length) {
        return result[0].account_id;
      }
      return null;
    },
  },

  created() {
    this.listAll();
    this.listMerchantParent();
  },
  computed: {},
  watch: {
    currentPage() {
      this.listAll();
    },
  },
};
</script>
